import React from "react";
import { useHistory } from "react-router-dom";

const Title = (props) => {
  const history = useHistory();
  return (
    <>
      <header>
        {props.withButton === true ? (
          <button onClick={() => history.goBack()}>Back</button>
        ) : null}
        <h2>{props.title} </h2>
      </header>
      <hr />
    </>
  );
};

export default Title;
