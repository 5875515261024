import React, { useState, useEffect } from "react";
import Footer from "../components/footer/Footer";
import Title from "../components/misc/Title";
import ImageOnlyModal from "../components/modal/ImageOnlyModal";
import IceCreamGelato from "../assets/dessert/ice_and_gelato.jpg"
import NavBar from "../components/navigation/NavBar";
import DelyCard from "../components/card/DelyCard";

import dessert from "../data/dessert"

const DessertItem = () => {
  const [visible, setvisible] = useState(false);

  const trigger = () => {
    if (visible) {
      setvisible(false);
    } else {
      setvisible(true);
    }
  };

  useEffect(() => {
    setTimeout(trigger, 3000);
  }, []);

  return (
    <>
      <NavBar />
      <main>
        <div id="swipe-up-indicator">
          Swipe Up
        </div>
        <Title withButton={true} title="Dessert" />
        <hr />
        <DelyCard menu={dessert} />
      </main>
      <Footer />
      <ImageOnlyModal img={IceCreamGelato} visible={visible} trigger={() => setvisible(false)} />
    </>
  );
};

export default DessertItem;
