import React from "react";
import MenuGrid from "../components/MenuGrid/MenuGrid";
import "./home.scss";
import FeaturedImage from "../assets/pizza-parlor/pizza/favorite/daet-favorite.png";
import MinimalFooter from "../components/footer/MinimalFooter";
import { useHistory } from "react-router-dom";

const PizzaParlor = () => {
  const history = useHistory();
  return (
    <div className="base">
      <img src={FeaturedImage} alt="" srcset="" className="featured-image" />
      <div className="featured">
        <div className="history-btn-container">
          <button onClick={() => history.goBack()}>Back</button>
        </div>
        <MinimalFooter />
      </div>
      <MenuGrid menuData={menuData} clickable={false} centerText="MENU" />
    </div>
  );
};

export default PizzaParlor;

const currentPath = "pizza-parlor";
const menuData = [
  {
    id: 1,
    name: "PIZZA",
    sub: "",
    path: currentPath + "/pizza",
  },
  {
    id: 2,
    name: "PASTA",
    sub: "",
    path: currentPath + "/pasta",
  },
  {
    id: 2,
    name: "MEALS & SHAWARMA",
    sub: "",
    path: currentPath + "/shawarma",
  },
  {
    id: 4,
    name: "DESSERT",
    sub: "",
    path: currentPath + "/dessert",
  },
];
