import React from "react";
import MenuGrid from "../components/MenuGrid/MenuGrid";
import "./home.scss";
import FeaturedImage from "../assets/pizza-parlor/pizza/favorite/daet-favorite.png";
import MinimalFooter from "../components/footer/MinimalFooter";
import { useHistory } from "react-router-dom";

const FrozenPizza = () => {
  const history = useHistory();
  return (
    <div className="base">
      <img src={FeaturedImage} alt="" srcset="" className="featured-image" />
      <div className="featured">
        <div className="history-btn-container">
          <button onClick={() => history.goBack()}>Back</button>
        </div>
        <MinimalFooter />
      </div>
      <MenuGrid
        menuData={menuData}
        clickable={false}
        centerText="FROZEN PIZZA"
      />
    </div>
  );
};

export default FrozenPizza;

const currentPath = "frozen-pizza";
const menuData = [
  {
    id: 1,
    name: "BUDGET SET",
    sub: null,
    path: currentPath + "/item/0",
  },
  {
    id: 2,
    name: "CLASSIC SET",
    sub: null,
    path: currentPath + "/item/1",
  },
  {
    id: 3,
    name: "FAVORITE SET",
    sub: null,
    path: currentPath + "/item/2",
  },
  {
    id: 4,
    name: "PIZZA BASE SET",
    sub: "Top your own pizza",
    path: currentPath + "/item/3",
  },
];
