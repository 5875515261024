import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../modal/Modal";
import "./pizza-card.scss";
import Fade from "react-reveal/Fade";

const PizzaCard = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({
    name: null,
    img: null,
    ingredients: null,
    price: { regular: null },
  });

  const modalTrigger = (menuItem) => {
    if (modalVisible === true) {
      setModalVisible(false);
    } else {
      setModalData(menuItem);
      setModalVisible(true);
    }
  };

  return (
    <>
      <section className="card-container">
        {props.menu.map((item, key) => (
          <CardItem
            menuItem={item}
            trigger={modalTrigger}
            key={key}
            clickableTag={props.clickableTag}
          />
        ))}
      </section>
      <Modal
        trigger={modalTrigger}
        menuItem={modalData}
        visible={modalVisible}
      />
    </>
  );
};

const CardItem = (props) => {
  const { name, img, price, isVege } = props.menuItem;

  return (
    <Fade bottom cascade>
      <div className="card-item">
        <img src={img} alt={name} />
        <div className="card-details">
          <h4>{name}</h4>
          {Object.entries(price).map((item, key) => {
            return (
              <span key={key} className="price">
                {`${item[0]}`}
                {item[1] !== null ? (
                  <>
                    {" "}
                    - &#8369; <b>{`${item[1]}`}</b>{" "}
                  </>
                ) : null}
                <br />
              </span>
            );
          })}
          {isVege ? (
            props.clickableTag ? (
              <div className="tag-parent">
                <Link className="tag" to="/vegetarian">
                  Vegetarian's Choice
                </Link>
              </div>
            ) : (
              <div className="tag-parent">
                <span className="tag">Vegetarian's Choice</span>
              </div>
            )
          ) : null}
        </div>
        <button onClick={() => props.trigger(props.menuItem)}>View</button>
      </div>
    </Fade>
  );
};

export default PizzaCard;
