import React from "react";
import { Redirect, Route, Switch } from "react-router";
import "./home.scss";
import PizzaParlor from "./PizzaParlor";
import PizzaMenu from "./PizzaMenu";
import Home from "./Home";
import PizzaItem from "./PizzaItem";
import AboutUs from "./AboutUs";
import PastaMenu from "./PastaMenu";
import PastaItem from "./PastaItem";
import DelyShop from "./DelyShop";
import DessertItem from "./DessertItem";
import ShawarmaItem from "./ShawarmaItem";
import FrozenPizza from "./FrozenPizza";
import FrozenPizzaItem from "./FrozenPizzaItem";
import NotFoundPage from "./NotFoundPage";
import BusinessTool from "./BusinessTool";
import MealsAndShawarma from "./MealsAndShawarma";
import VegetarianItem from "./VegetarianItem";
import Vegetarian from "./Vegetarian";
import FrozenPizzaReseller from "./FrozenPizzaReseller";
import StarterKit from "./StarterKit";

const BaseSwitch = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/pizza-parlor">
        <PizzaParlor />
      </Route>
      <Route path="/dely-shop">
        <DelyShop />
      </Route>
      <Route exact path="/vegetarian">
        <Vegetarian />
      </Route>
      <Route path="/vegetarian/:categoryId">
        <VegetarianItem />
      </Route>

      <Route exact path="/frozen-pizza">
        <FrozenPizza />
      </Route>
      <Route exact path="/pizza-parlor/pizza">
        <PizzaMenu />
      </Route>
      <Route exact path="/pizza-parlor/pasta">
        <PastaMenu />
      </Route>
      <Route path="/pizza-parlor/pizza/item/:categoryId">
        <PizzaItem />
      </Route>
      <Route exact path="/pizza-parlor/dessert">
        <DessertItem />
      </Route>
      <Route exact path="/pizza-parlor/shawarma">
        <MealsAndShawarma />
      </Route>
      <Route path="/pizza-parlor/shawarma/:categoryId">
        <ShawarmaItem />
      </Route>

      <Route path="/pizza-parlor/pasta/item/:categoryId">
        <PastaItem />
      </Route>
      <Route path="/frozen-pizza/item/:categoryId">
        <FrozenPizzaItem />
      </Route>
      <Route path="/about-us">
        <AboutUs />
      </Route>
      <Route exact path="/business-tool">
        <BusinessTool />
      </Route>
      <Route exact path="/business-tool/frozen-pizza-reseller">
        <FrozenPizzaReseller />
      </Route>
      <Route  path="/business-tool/starter-kit">
        <StarterKit />
      </Route>
      <Route path="/404">
        <NotFoundPage />
      </Route>
      <Redirect to="/404" />
    </Switch>
  );
};

export default BaseSwitch;
