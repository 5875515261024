import VanillaGelato from "../assets/dessert/vanilla_gelato.jpg";
import Lemonsito1Liter from "../assets/dessert/lemonsito_1L.jpeg";
import Lemonsito350ml from "../assets/dessert/lemonsito_350ml.jpeg";
import GelatoAdds from "../assets/dessert/gelato-adds.jpg";

const dessert = [
  {
    name: "Vanilla Gelato",
    img: VanillaGelato,
    ingredients:
      "An italian frozen dessert with a denser texture and has a lesser fats than ice cream",
    price: ["200g", "500g"],
  },
  {
    name: "Lemonsito Juice 1L",
    img: Lemonsito1Liter,
    ingredients: "",
    price: ["1L"],
  },
  {
    name: "Lemonsito Juice 350ml",
    img: Lemonsito350ml,
    ingredients: "",
    price: ["350ml"],
  },
  {
    name: "Gelato Add-ons",
    img: GelatoAdds,
    ingredients: "Caramel Syrup ",
    price: ["Caramel (100g)", "Chocolate (30g)"],
  },
];

export default dessert;
