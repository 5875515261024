import StartKit10K from "./../assets/business-tools/Start Kit 10k.jpg";
import StartKit20K from "./../assets/business-tools/Start Kit 20k.jpg";
import StartKit35K from "./../assets/business-tools/Start Kit 35k.jpg";
export const startKit = [
  {
    info: [
      "1pc - Pizza Spatula (Small)",
      "1pc - Pizza Spatula (Big)",
      "1pc - Pizza Cutting Board (Regular)",
      "1pc - Pizza Cutting Board (Family)",
      "1pc - Pizza Baking Net (Regular)",
      "1pc - Pizza Baking Net (Family)",
      "3pcs - Tarpaulin",
      "1pc - Pizza Slicer",
      "200ml - Pizza Sauce(Hot)",
      "1pc - Pineapple can",
      "2pcs (250g) - Ham",
      "500g - Pepperoni",
      '1pc - Plastic(8" x 11")',
      "100g - Parmesan",
      "1pc - Mashroom can",
      "1pc - Corn",
      "2pcs - Plastic for Corn",
      "2pcs (50g) - Beef Pack",
      "1pc (150ml) - Black Olives Sliced",
      "40g - Oregano Leaves Ground Mooore",
      "100pcs - Sauce Cup",
      "10pcs - Frozen Pizza Solo Twin Pack",
      "10pcs - Frozen Base Pizza Regular",
      "10pcs - Frozen Base Pizza Family",
    ],
    price: 10000,
  },
];

export const startKitImage = [StartKit10K, StartKit20K, StartKit35K];
