import React, { useState } from "react";
import Footer from "../components/footer/Footer";
import Title from "../components/misc/Title";
import NavBar from "../components/navigation/NavBar";
import ImageOnlyModal from "../components/modal/ImageOnlyModal";
import { startKitImage } from "../data/startKit";
import "./business.scss";
const StarterKit = () => {
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState(startKitImage[0]);

  const showImage = (image) => {
    setVisible(true);
    setImage(image);
  };

  return (
    <>
      <NavBar />
      <main>
        <Title withButton={true} title="Starter Kit" />
        <hr />
        <section className="gallery-1">
          {startKitImage.map((item, key) => (
            <img src={item} key={key} onClick={() => showImage(item)} />
          ))}
        </section>
        <a href="https://m.me/pizzaitalianvero" target="_blank" rel="noreferrer" id="starter-button">
        Contact Us
      </a>
      </main>
      <Footer />
      <ImageOnlyModal
        img={image}
        visible={visible}
        trigger={() => setVisible(false)}
      />
    </>
  );
};

export default StarterKit;
