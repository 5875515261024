import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoHolder from "./../misc/LogoHolder";
import "./nav.scss";

const NavBar = () => {
  const [drawerActive, setDrawerActive] = useState(false);
  const triggerOverlay = () => {
    drawerActive === true ? setDrawerActive(false) : setDrawerActive(true);
  };
  return (
    <>
      <NavDrawer active={drawerActive} click={triggerOverlay} />
      <nav>
        <Link to="/">
          <LogoHolder width="75" height="75" id="nav-logo" />
        </Link>
        <div className="nav-item-container">
          {navItem.map((item) => (
            <NavItem name={item.name} path={item.path} key={item.id} />
          ))}
        </div>
        <FontAwesomeIcon
          icon={faBars}
          className="hamburger"
          onClick={triggerOverlay}
        />
      </nav>
    </>
  );
};

const NavDrawer = (props) => {
  return (
    <section className={`nav-drawer ${props.active ? "active" : ""}`}>
      <FontAwesomeIcon
        icon={faTimes}
        className="nav-close-button"
        onClick={props.click}
      />
      {navItem.map((item) => (
        <NavItem name={item.name} path={item.path} key={item.id} />
      ))}
    </section>
  );
};

const NavItem = (props) => {
  return (
    <div className="nav-item">
      <Link to={props.path}>
        {props.name}
        <span className="nav-border-bottom"></span>
      </Link>
    </div>
  );
};

export default NavBar;

const navItem = [
  {
    id: 1,
    name: "Pizza Parlor Menu",
    path: "/pizza-parlor",
  },
  {
    id: 2,
    name: "Frozen Pizza",
    path: "/frozen-pizza",
  },
  {
    id: 3,
    name: "Dely Shop",
    path: "/dely-shop",
  },
  {
    id: 4,
    name: "Business Tool",
    path: "/business-tool",
  },
  {
    id: 5,
    name: "About Us",
    path: "/about-us",
  },
];
