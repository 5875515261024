import Basic1 from "../assets/frozen/basic_1.jpg";
import Basic2 from "../assets/frozen/basic_2.jpg";

import Box from "../assets/frozen/box.jpg";

import Budget2 from "../assets/frozen/budget_2.jpg";
import BudgetPreparation from "../assets/frozen/budget_preparation.jpg";

import ThreeCheese from "../assets/frozen/budget/3-cheese.png";
import HamAndCheese from "../assets/frozen/budget/ham-and-cheese.png";
import Hawaiian from "../assets/frozen/budget/hawaiian.png";

import Classic2 from "../assets/frozen/classic_2.jpg";
import ClassicPreparation from "../assets/frozen/classic_preparation.jpg";

import FourCheese from "../assets/frozen/classic/4-cheese.png";
import Capriciossa from "../assets/frozen/classic/capriciossa.png";
import Pepperoni from "../assets/frozen/classic/pepperoni.png";

import Favorite2 from "../assets/frozen/favorite_2.jpg";
import FavoritePreparation from "../assets/frozen/favorite_preparation.jpg";

import DaetFavorite from "../assets/frozen/favorite/daet-favorite.png";
import DaetOnTheGo from "../assets/frozen/favorite/daet-on-the-go.png";
import Vegetarian from "../assets/frozen/favorite/vegetarian.png";

import BasicRegular from "../assets/frozen/basic-regular.png";
import BasicFamily from "../assets/frozen/basic-family-pizza.png";
import BasicPrepration from "../assets/frozen/basic-preparation.jpg";

const frozen = [
  {
    id: 0,
    type: "Budget Set",
    description: null,
    img: [BudgetPreparation, Budget2, Box],
    pizza: [
      {
        name: "3 Cheese",
        type: "Regular",
        img: ThreeCheese,
        pcs: 1,
      },
      {
        name: "Ham & Cheese",
        type: "Regular",
        img: HamAndCheese,
        pcs: 1,
      },
      {
        name: "Hawaiian",
        type: "Regular",
        img: Hawaiian,
        pcs: 2,
      },
    ],
    price: null,
  },
  {
    id: 1,
    type: "Classic Set",
    description: null,
    img: [ClassicPreparation, Classic2, Box],
    pizza: [
      {
        name: "4 Cheese",
        type: "Regular",
        img: FourCheese,
        pcs: 1,
      },
      {
        name: "Pepperoni",
        type: "Regular",
        img: Pepperoni,
        pcs: 2,
      },
      {
        name: "Capriciossa",
        type: "Regular",
        img: Capriciossa,
        pcs: 1,
      },
    ],
    price: null,
  },
  {
    id: 2,
    type: "Favorite Set",
    description: null,
    img: [FavoritePreparation, Favorite2, Box],
    pizza: [
      {
        name: "Daet Favorite",
        type: "Regular",
        img: DaetFavorite,
        pcs: 1,
      },
      {
        name: "Vegetarian",
        type: "Regular",
        img: Vegetarian,
        pcs: 1,
      },
      {
        name: "Daet on the Go",
        type: "Regular",
        img: DaetOnTheGo,
        pcs: 1,
      },
    ],
    price: null,
  },

  {
    id: 3,
    type: "Pizza Base Set",
    description:
      "Buy our basic pizza and put your desired toppings. (crust, suce, spices, mozzarella cheeses)",
    img: [BasicPrepration, Basic1, Basic2],
    pizza: [
      {
        name: "Basic Pizza",
        type: "Regular",
        img: BasicRegular,
        pcs: 4,
        price: null,
      },
      {
        name: "Basic Pizza",
        type: "Family",
        img: BasicRegular,
        pcs: 4,
        price: null,
      },
    ],
  },
];

export default frozen;
