import React from "react";
import NavBar from "../components/navigation/NavBar";
import { Link, useParams } from "react-router-dom";
import Title from "../components/misc/Title";
import Pasta from "../data/pasta";
import Footer from "../components/footer/Footer";
import PastaCard from "../components/card/PastaCard";
const PastaItem = () => {
  let { categoryId } = useParams();
  let menuData = Pasta[categoryId];
  return (
    <>
      <NavBar />
      <main>
      <div id="swipe-up-indicator">
          Swipe Up
        </div>
        <Title withButton={true} title={menuData.type} />
        <hr />
        <PastaCard menu={menuData.menu} clickableTag={true}/>
        <div className="vege-btn-c">
          <Link to="/vegetarian">View All Vegetarian Food</Link>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PastaItem;
