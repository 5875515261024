import React, { useState } from "react";
import Footer from "../components/footer/Footer";
import Title from "../components/misc/Title";
import SubTitle from "../components/misc/SubTitle";
import NavBar from "../components/navigation/NavBar";
import ImageOnlyModal from "../components/modal/ImageOnlyModal";
import "./business.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookMessenger,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

import BasicPreparation from "../assets/frozen/basic-preparation.jpg";
import BudgetPreparation from "../assets/frozen/budget_preparation.jpg";
import ClassicPreparation from "../assets/frozen/classic_preparation.jpg";
import FavoritePreparation from "../assets/frozen/favorite_preparation.jpg";
import Budget from "../assets/frozen/budget_2.jpg";
import Classic from "../assets/frozen/classic_2.jpg";
import Favorite from "../assets/frozen/favorite_2.jpg";
import Basic from "../assets/frozen/basic_1.jpg";

import { Link } from "react-router-dom";

const FrozenPizzaReseller = () => {
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState(BasicPreparation);

  const showImage = (image) => {
    setVisible(true);
    setImage(image);
  };

  return (
    <>
      <NavBar />
      <main>
        <Title withButton={true} title="Frozen Pizza Reseller" />
        <hr />
        <section id="business-info">
          <p>
            You like to start your business with pizza? Resellers can avail{" "}
            <span className="special-text">20%</span> discount from its original
            price. You are very welcome to visit us and let's discuss how to do
            it!
          </p>
          <p>
            If you want to buy/sell 10 or more sets of any frozen pizza sets,
            you can avail <span className="special-text">20%</span> discount on
            original price.
          </p>
          <br />
          <p className="caption"> For more business offers, just contact us.</p>
          <div className="telco">
            <span>SMART</span>
            <b>(+63) 0929 284 8041</b>
          </div>
          <div className="telco">
            <span>GLOBE</span>
            <b>(+63) 0956 190 8117</b>
          </div>
          <br />
          <p className="caption"> or message us via</p>
          <div className="social">
            <a
              href="https://m.me/pizzaitalianvero"
              target="_blank"
              rel="noreferrer"
              className="light"
            >
              <FontAwesomeIcon icon={faFacebookMessenger} />
            </a>
            <a
              href="https://facebook.com/pizzaitalianvero"
              target="_blank"
              rel="noreferrer"
              className="light"
            >
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
          </div>
          <div id="gallery">
            <SubTitle title="WHAT WE OFFER" />
          </div>
          <br />
          <p>
            The reseller discount is only available for{" "}
            <Link to="/frozen-pizza">Frozen Pizza</Link> sets.
          </p>
          <ul>
            <li>
              <Link to="/frozen-pizza/item/0">Budget Set</Link>
            </li>
            <li>
              <Link to="/frozen-pizza/item/1">Classic Set</Link>
            </li>
            <li>
              <Link to="/frozen-pizza/item/2">Favorite Set</Link>
            </li>
            <li>
              <Link to="/frozen-pizza/item/3">Pizza Base Set</Link>
            </li>
          </ul>
          <section className="gallery">
            {images.map((item, key) => (
              <img src={item} key={key} onClick={() => showImage(item)} />
            ))}
          </section>
        </section>
      </main>
      <Footer />
      <ImageOnlyModal
        img={image}
        visible={visible}
        trigger={() => setVisible(false)}
      />
    </>
  );
};

export default FrozenPizzaReseller;

const images = [
  BasicPreparation,
  BudgetPreparation,
  ClassicPreparation,
  FavoritePreparation,
  Budget,
  Classic,
  Favorite,
  Basic,
];
