import React from "react";
import SubTitle from "../components/misc/SubTitle";
import Title from "../components/misc/Title";
import NavBar from "../components/navigation/NavBar";
import FeaturedImage from "../assets/about-us/front-page-small.jpg";
import Historical from "../assets/about-us/historical-picture.jpg";
import "./about-us.scss";
import MapDisplay from "../components/misc/MapDisplay";
import Footer from "../components/footer/Footer";
const AboutUs = () => {
  return (
    <>
      <NavBar />
      <main>
        <Title withButton={false} title="About Us" />
        <section
          className="body cover-img"
          style={{
            backgroundImage: `radial-gradient(rgba(18, 18, 18, 0.0),rgba(18, 18, 18, 1)),url(${FeaturedImage})`,
          }}
        ></section>
        <SubTitle title="Pizza" />
        <section className="body">
          <p className="paragraph">From Wikipedia, the free encyclopedia</p>
          <p className="paragraph">
            “ <b>Pizza Italian: [pittsa],</b> in the US sometimes called pizza
            pie[1], is an oven-baked, flat, disc-shaped bread typically topped
            with a tomato sauce, cheese (usually mozzarella), and various
            toppings, depending on the culture. Since the original pizza,
            several other types of pizzas have evolved. Originating in
            Neapolitan cuisine (Italy), the dish has become popular in many
            different parts of the world.
          </p>
          <p className="paragraph">
            The Ancient Greeks covered their bread with oils, herbs and cheese.
            The Romans developed placenta, a sheet of flour topped with cheese
            and honey and flavored with bay leaves.
          </p>
          <p className="paragraph">
            Modern pizza originated in Italy as the Neapolitan pie with tomato.
            In 1889, cheese was added.”
          </p>
          <p className="paragraph">
            In <b>October 2010</b>, <br />
            Ultimate Italian pizza come in{" "}
            <b>Bicol, Daet, Camarines Norte, Philippines.</b>
          </p>
        </section>
        <section className="body" id="img-container">
          <img src={Historical} alt="" />
        </section>
        <SubTitle title="Our Location" />
        <p className="paragraph">
          David street, Purok 2, Barangay V 4600 Daet, Philippines
        </p>
        <MapDisplay name="Brankolei Pizza Italian Vero" />
      </main>
      <Footer />
    </>
  );
};

export default AboutUs;
