import React from 'react'
import { Link } from "react-router-dom";
import Footer from '../components/footer/Footer';
import "./error.scss";

const NotFoundPage = () => {
    return (
        <>
        <main className="error">
            <h1>Error <span>4</span>0<span>4</span></h1>
            <p className="caption">Page Not Found</p>
            <p>Sorry! We cannot find the page that you are looking for.</p>
            <Link to="/">Return Home</Link>
        </main>
        </>
        
    )
}

export default NotFoundPage
