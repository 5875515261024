import React from "react";
import LogoImage from "../../assets/logo.png";

function LogoHolder(props) {
  return (
    <div className="logo-container">
      <img
        src={LogoImage}
        alt="Brankolei Pizza Logo"
        width={props.width}
        height={props.height}
      />
    </div>
  );
}

export default LogoHolder;
