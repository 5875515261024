import React from "react";
import "./pizza-card.scss";
import Fade from "react-reveal/Fade";

const FrozenCard = (props) => {
  return (
    <>
      <section className="card-container">
        {props.pizza.map((item, key) => (
          <CardItem pizza={item} key={key} />
        ))}
      </section>
    </>
  );
};

const CardItem = (props) => {
  const { name, type, img, pcs } = props.pizza;

  return (
    <Fade bottom cascade>
      <div className="card-item" style={{ textAlign: "center" }}>
        <img src={img} alt={name} />
        <h4>{name}</h4>
        <h5>{type}</h5>
        <span className="caption">{pcs} piece/s</span>
      </div>
    </Fade>
  );
};

export default FrozenCard;
