import React from "react";

const SubTitle = (props) => {
  return (
    <>
      <h3>{props.title}</h3>
      <hr />
    </>
  );
};

export default SubTitle;
