import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import "./tri-menu.scss";

const TriMenu = (props) => {
  return (
    <section className="menu-container">
      {props.menuData.map((item) => (
        <MenuItem
          sub={item.sub}
          name={item.name}
          key={item.id}
          path={item.path}
        />
      ))}
    </section>
  );
};

const MenuItem = (props) => {
  return (
    <div className="menu-item">
      <Link to={`/${props.path}`}>
        <div className="content-container">
          <Fade bottom>
            <h3>{props.name}</h3>
            <span className="sub caption">{props.sub}</span>
          </Fade>
        </div>
      </Link>
    </div>
  );
};

export default TriMenu;

