import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Title from "../components/misc/Title";
import SubTitle from "../components/misc/SubTitle";
import NavBar from "../components/navigation/NavBar";
import PizzaCard from "../components/card/PizzaCard";
import PastaCard from "../components/card/PastaCard";
import DelyCard from "../components/card/DelyCard";
import vegetarian from "../data/vegetarian";

const VegetarianItem = () => {
  let { categoryId } = useParams();
  let menuData = vegetarian[categoryId];
  let card = null;
  if (categoryId == 0) {
    card = <PizzaCard menu={menuData.menu} clickableTag={false} />;
  } else if (categoryId == 1) {
    card = <PastaCard menu={menuData.menu} clickableTag={false} />;
  } else if (categoryId == 2) {
    card = <DelyCard menu={menuData.menu} clickableTag={false} />;
  } else {
    card = null;
  }

  return (
    <>
      <NavBar />
      <main>
        <div id="swipe-up-indicator">Swipe Up</div>
        <Title withButton={true} title="Vegetarian's Choice" />
        <br />
        <SubTitle title={menuData.name} />
        {card}
      </main>
      <Footer />
    </>
  );
};

export default VegetarianItem;
