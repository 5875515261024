import React from "react";
import Footer from "../components/footer/Footer";
import Title from "../components/misc/Title";
import TriMenu from "../components/MenuGrid/TriMenu";
import NavBar from "../components/navigation/NavBar";

const BusinessTool = () => {
  return (
    <>
      <NavBar />
      <main>
        <Title withButton={true} title="Business Tool" />
        <hr />
        <TriMenu menuData={menuData} />
      </main>
      <Footer />
    </>
  );
};

export default BusinessTool;

const currPath = "business-tool";
const menuData = [
  {
    id: 0,
    name: "Starter Kit",
    sub: "",
    path: currPath + "/starter-kit",
  },
  {
    id: 1,
    name: "Frozen Pizza Reseller",
    sub: "",
    path: currPath + "/frozen-pizza-reseller",
  },
];
