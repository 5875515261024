import Falafel from "../assets/shawarma/falafel.jpg";
import Mediterranean from "../assets/shawarma/mediterranean.jpg";
import LambAndBeefKhebab from "../assets/shawarma/lamb_&_beef_khebab.jpg";
import Milanese from "../assets/shawarma/milanese.jpg";
import CheeseStick from "../assets/shawarma/fryed-cheese-sticks.jpg";
import ChickenBreast from "../assets/shawarma/chicken_breast.jpg";

import WiennerCone from "../assets/shawarma/wienner-cone.jpg";
import ChickenCone from "../assets/shawarma/chicken-cone.jpg";
import CheeseCone from "../assets/shawarma/cheese-cone.jpg";

import TartareSauce from "../assets/shawarma/tartar-sauce.jpg";
import HotSauce from "../assets/shawarma/hot-sauce.jpg";
import Hummus from "../assets/shawarma/hummus.jpg";
import BreadLoaf from "../assets/shawarma/bread-loaf.jpg";
import Parmigiana from "../assets/shawarma/parmigiana.jpg";

import SlicedBread from "../assets/shawarma/sliced-bread.jpg";
import Rice from "../assets/shawarma/rice.jpg";
const shawarma = [
  {
    type: "Shawarma Cones",
    menu: [
      {
        name: "Falafel Hummus Cone",
        img: Falafel,
        isVege: true,
        ingredients:
          "Falafel with hummus,fresh salad, arabian sauce etc. (Do not forget that food need to be masarap, talaga! but need to be nutrient and healthy as well !!!)",
        price: [],
      },
      {
        name: "Pork Tenderloin Cone ",
        img: WiennerCone,
        isVege: false,
        ingredients:
          "Fried Pork tenderloin (wiener shnitzel), fresh salad, tartare sauce",
        price: [],
      },
      {
        name: "Chicken Cone",
        img: ChickenCone,
        isVege: false,
        ingredients: "Fried Chicken breast, tartare sauce, fresh salad",
        price: [],
      },
      {
        name: "Cheese Cone",
        img: CheeseCone,
        isVege: false,
        ingredients: "Cheese stick, tartare sauce, fresh salad",
        price: [],
      },
      {
        name: "Mediterranean Shawarma Cone",
        img: Mediterranean,
        isVege: false,
        ingredients:
          "Cheese chilly flakes sauce, chevapchich ( beef and pork meat longanesa without skin) fresh salad, Arabian sauce, pita bread cone ",
        price: [],
      },

      {
        name: "Lamb & Beef Khebab Shawarma Cone",
        img: LambAndBeefKhebab,
        ingredients: "NEW in Daet !!! (Imported Australian tupa)",
        price: [],
      },
    ],
  },
  {
    type: "Meals",
    menu: [
      {
        name: "Cheese Sticks",
        img: CheeseStick,
        isVege: true,
        ingredients:
          " with tartare sauce and fries(optional) or rice (optional)",
        price: [],
      },
      {
        name: "Milanese Schnitzel (Pork Tenderloin)",
        img: Milanese,
        isVege: false,
        ingredients:
          " with tartare sauce and fries(optional) or rice (optional)",
        price: [],
      },
      {
        name: "Fried Chicken (Schnitzel) breast",
        img: ChickenBreast,
        isVege: false,
        ingredients:
          "with tartare sauce and fries(optional) or rice (optional)",
        price: [],
      },
      {
        name: "Fried Chicken Parmigiana",
        img: Parmigiana,
        isVege: false,
        ingredients:
          "with tartare sauce and fries(optional) or rice (optional)",
        price: [],
      },
    ],
  },

  {
    type: "Add-ons",
    menu: [
      {
        name: "Bread",
        img: SlicedBread,
        isVege: false,
        ingredients: "An adds for your meals",
        price: ["2 pcs"],
      },
      {
        name: "Rice",
        img: Rice,
        isVege: false,
        ingredients: "An adds for your meals",
        price: ["1 cup"],
      },
      {
        name: "Tomato Hot Sauce",
        img: HotSauce,
        isVege: false,
        ingredients: "An adds for your cones and meals",
        price: ["30ml"],
      },
      {
        name: "Tartare Sauce",
        img: TartareSauce,
        isVege: false,
        ingredients: "An adds for your cones and meals",
        price: ["30ml"],
      },
      {
        name: "Hummus",
        img: Hummus,
        isVege: false,
        ingredients: "An adds for your cones and meals",
        price: ["30ml"],
      },

      {
        name: "Bread Loaf (Advanced order only)",
        img: BreadLoaf,
        isVege: false,
        ingredients: "Soft white loaf bread (No Milk, No Butter, No Egg)",
        price: [],
      },
    ],
  },
];

export default shawarma;
