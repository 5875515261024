import React, { useState } from "react";
import {
  faFacebookMessenger,
  faFacebookSquare,
  faPaypal,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./footer.scss";

const Footer = () => {
  const [date, setDate] = useState(new Date().getFullYear());
  return (
    <footer className="colored">
      <h5>BRANKOLEI PIZZA ITALIAN VERO</h5>
      <section>
        <h6>SOCIAL LINKS</h6>
        <a
          href="https://facebook.com/pizzaitalianvero"
          target="_blank"
          rel="noreferrer"
          className="light"
        >
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
        <a
          href="https://m.me/pizzaitalianvero"
          target="_blank"
          rel="noreferrer"
          className="light"
        >
          <FontAwesomeIcon icon={faFacebookMessenger} />
        </a>
      </section>
      <section>
        <h6>CONTACT US</h6>
        <p className="light">
          Smart : (+63) 0929 284 8041 <br />
          Globe : (+63) 0956 190 8117
        </p>
      </section>
      <section>
        <h6>LOCATION</h6>
        <p className="light">
          
          David street, Purok 2, Barangay V 4600 Daet, Philippines
        </p>
      </section>
      <section>
        <h6>PAY US VIA PAYPAL</h6>
        <a
          href="https://www.paypal.com/myaccount/transfer/homepage?from=SUM-QuickLink"
          target="_blank"
          rel="noreferrer"
          className="light"
        >
          <FontAwesomeIcon icon={faPaypal} />
        </a>
        <p className="light">brankodom@yahoo.com</p>
      </section>
      <section>
        <p className="light">
          &copy; {date}. Brankolei Pizza Italian Vero.
          <br /> All Rights Reserved
        </p>
      </section>
    </footer>
  );
};

export default Footer;
