import React from "react";
import "./pizza-card.scss";
import Fade from "react-reveal/Fade";

const FrozenCard2 = (props) => {
  return (
    <>
      <section className="card-container">
        {props.pizza.map((item, key) => (
          <CardItem pizza={item} key={key} />
        ))}
      </section>
    </>
  );
};

const CardItem = (props) => {
  const { name, type, img, pcs, price } = props.pizza;

  return (
    <Fade bottom cascade>
      <div className="card-item" style={{ textAlign: "center" }}>
        {type === "Regular" ? (
          <img src={img} alt={name} className="regular-base" />
        ) : (
          <img src={img} alt={name} />
        )}
        <h4>{name}</h4>
        <h5>{type}</h5>
        <span className="caption">{pcs} piece/s</span>
        {price !== null ? <h4>&#8369; {price}</h4> : null}
      </div>
    </Fade>
  );
};

export default FrozenCard2;
