import React from "react";
import { Link } from "react-router-dom";
import "./menu-grid.scss";
import $ from "jquery";
import "../../utils/jquery.arctext";
import LogoHolder from "../misc/LogoHolder";
import Fade from "react-reveal/Fade";
export default class MenuGrid extends React.Component {
  constructor(props) {
    super(props);
    this.menuData = props.menuData;
  }

  componentDidMount() {
    $(".curved-text").arctext({ radius: 80 });
  }

  render() {
    return (
      <div className="menu-grid">
        <div className="circle-container">
          <h4 className="curved-text">{this.props.centerText}</h4>
          {this.props.clickable === true ? (
            <Link to={this.props.to} className="centered-logo">
              <LogoHolder width={125} height={125} />
            </Link>
          ) : (
            <LogoHolder width={125} height={125} className="centered-logo" />
          )}
        </div>

        {this.menuData.map((item) => (
          <MenuItem
            sub={item.sub}
            name={item.name}
            key={item.id}
            path={item.path}
          />
        ))}
      </div>
    );
  }
}

const MenuItem = (props) => {
  return (
    <div className="menu-item">
      <Link to={`/${props.path}`}>
        <div className="content-container">
          <Fade bottom>
            <h3>{props.name}</h3>
            <span className="sub caption">{props.sub}</span>
          </Fade>
        </div>
      </Link>
    </div>
  );
};
