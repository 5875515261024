import FourCheese from "../assets/pizza-parlor/pasta/canneloni/4-cheese.jpg";
import BeefAndCheese from "../assets/pizza-parlor/pasta/canneloni/beef-and-cheese.jpg";

import ChickenAlfredo from "../assets/pizza-parlor/pasta/fettucine/chicken-alfredo-fettuccini.jpg";
import SpinachFetuccinni from "../assets/pizza-parlor/pasta/fettucine/spinach-fetuccinii.jpg";
import TunaMarinara from "../assets/pizza-parlor/pasta/fettucine/tuna-marinara.jpg";

import Beef from "../assets/pizza-parlor/pasta/lasagna/beef.jpg";
import Spinach from "../assets/pizza-parlor/pasta/lasagna/spinach.jpg";
import Tuna from "../assets/pizza-parlor/pasta/lasagna/tuna.jpg";
import Vege from "../assets/pizza-parlor/pasta/lasagna/vege.jpg";

import Bolognese from "../assets/pizza-parlor/pasta/spaghetti/bolognese.jpg";
import Carbonara from "../assets/pizza-parlor/pasta/spaghetti/carbonara-supreme.jpg";
import Vegetarian from "../assets/pizza-parlor/pasta/spaghetti/vegetarian.jpg";

const pasta = [
  {
    type: "Spaghetti",
    menu: [
      {
        name: "Vegetarian",
        img: Vegetarian,
        isVege: true,
        ingredients: "",
        price: {
          solo: null,
          pan: null,
        },
      },
      {
        name: "Bolognese",
        img: Bolognese,
        isVege: false,
        ingredients: "",
        price: {
          solo: null,
          pan: null,
        },
      },
      {
        name: "Carbonara",
        img: Carbonara,
        isVege: false,
        ingredients: "",
        price: {
          solo: null,
          pan: null,
        },
      },
    ],
  },
  {
    type: "Lasagna",
    menu: [
      {
        name: "Vege",
        isVege: true,
        img: Vege,
        ingredients: "",
        price: {
          double: null,
          pan: null,
        },
      },
      {
        name: "Beef",
        img: Beef,
        isVege: false,
        ingredients: "",
        price: {
          double: null,
          pan: null,
        },
      },
      {
        name: "Tuna",
        img: Tuna,
        isVege: false,
        ingredients: "",
        price: {
          double: null,
          pan: null,
        },
      },
      {
        name: "Spinach",
        img: Spinach,
        isVege: true,
        ingredients: "",
        price: {
          double: null,
          pan: null,
        },
      },
    ],
  },
  {
    type: "Canneloni",
    menu: [
      {
        name: "Beef & Cheese",
        img: BeefAndCheese,
        isVege: false,
        ingredients: "",
        price: {
          solo: null,
          pan: null,
        },
      },
      {
        name: "4 Cheese",
        img: FourCheese,
        isVege: true,
        ingredients: "",
        price: {
          solo: null,
          pan: null,
        },
      },
    ],
  },
  {
    type: "Fettucini",
    menu: [
      {
        name: "Tuna Marinara",
        img: TunaMarinara,
        isVege: false,
        ingredients: "",
        price: {
          solo: null,
        },
      },
      {
        name: "Spinach Fettucini",
        img: SpinachFetuccinni,
        isVege: true,
        ingredients: "",
        price: {
          solo: null,
        },
      },

      {
        name: "Chicken Alfredo",
        img: ChickenAlfredo,
        isVege: false,
        ingredients: "",
        price: {
          solo: null,
        },
      },
    ],
  },
];

export default pasta;
