import React from "react";
import NavBar from "../components/navigation/NavBar";
import Title from "../components/misc/Title";
import dely from "../data/dely";
import Footer from "../components/footer/Footer";
import DelyCard from "../components/card/DelyCard";

const DelyShop = () => {
  return (
    <>
      <NavBar />
      <main>
        <div id="swipe-up-indicator">Swipe Up</div>
        <Title withButton={true} title="Dely Shop" />
        <hr />
        <DelyCard menu={dely} />
      </main>
      <Footer />
    </>
  );
};

export default DelyShop;
