import ThreeCheese from "../assets/pizza-parlor/pizza/budget/3-cheese.png";
import FourCheese from "../assets/pizza-parlor/pizza/classic/4-cheese.png";
import Vegetarian from "../assets/pizza-parlor/pizza/favorite/vegetarian.png";
import SixCheese from "../assets/pizza-parlor/pizza/overload/6-cheese.png";
import Vege from "../assets/pizza-parlor/pasta/lasagna/vege.jpg";
import Spinach from "../assets/pizza-parlor/pasta/lasagna/spinach.jpg";
import FourCheeseCanneloni from "../assets/pizza-parlor/pasta/canneloni/4-cheese.jpg";
import SpinachFetuccinni from "../assets/pizza-parlor/pasta/fettucine/spinach-fetuccinii.jpg";
import CheeseStick from "../assets/shawarma/fryed-cheese-sticks.jpg";
import Falafel from "../assets/shawarma/falafel.jpg";

import Vegetarian1 from "../assets/pizza-parlor/pasta/spaghetti/vegetarian.jpg";
const vegetarian = [
  {
    name: "Pizza",
    menu: [
      {
        name: "3 Cheese",
        img: ThreeCheese,
        type: "Budget",
        isVege: true,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, parmesan cheese, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },

      {
        name: "4 Cheese",
        img: FourCheese,
        type: "Classic",
        isVege: true,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, parmesan cheese, (blue or cheddar cheese optional), spices",

        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Vegetarian",
        img: Vegetarian,
        type: "Favorite",
        isVege: true,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, fresh peppers, mushrooms, onion, basil tomato, black olives, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "6 Cheese",
        img: SixCheese,
        type: "Overload",
        isVege: true,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, Mixture of different mozzarella cheeses, parmesan, cream cheese, cheddar cheese and one more, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
    ],
  },

  {
    name: "Pasta",
    menu: [
      {
        name: "Vegetarian",
        img: Vegetarian1,
        type: "Spaghetti",
        isVege: true,
        ingredients: "",
        price: {
          solo: null,
          pan: null,
        },
      },
      {
        name: "Vege",
        img: Vege,
        type: "Lasagna",
        isVege: true,

        ingredients: "",
        price: {
          double: null,
          pan: null,
        },
      },
      {
        name: "Spinach",
        img: Spinach,
        type: "Lasagna",
        isVege: true,
        ingredients: "",
        price: {
          double: null,
          pan: null,
        },
      },
      {
        name: "4 Cheese",
        img: FourCheeseCanneloni,
        type: "Canneloni",
        isVege: true,
        ingredients: "",
        price: {
          solo: null,
          pan: null,
        },
      },
      {
        name: "Spinach Fettucini",
        img: SpinachFetuccinni,
        type: "Fettucini",
        isVege: true,
        ingredients: "",
        price: {
          solo: null,
        },
      },
    ],
  },
  {
    name: "Meals & Shawarma",
    menu: [
      {
        name: "Falafel Hummus Cone",
        img: Falafel,
        type: "Shawarma Cones",
        isVege: true,
        ingredients:
          "Falafel with hummus,fresh salad, arabian sauce etc. (Do not forget that food need to be masarap, talaga! but need to be nutrient and healthy as well !!!)",
        price: [],
      },
      {
        name: "Cheese Sticks",
        type: "Meals",
        img: CheeseStick,
        isVege: true,
        ingredients:
          " with tartare sauce and fries(optional) or rice (optional)",
        price: [],
      },
    ],
  },
];

export default vegetarian;
