import React from "react";
import Footer from "../components/footer/Footer";
import Title from "../components/misc/Title";
import NavBar from "../components/navigation/NavBar";
import TriMenu from "../components/MenuGrid/TriMenu"

const MealsAndShawarma = () => {
  return (
    <>
      <NavBar />
      <main>
      <div id="swipe-up-indicator">
          Swipe Up
        </div>
        <Title withButton={true} title="Meals & Shawarma" />
        <hr />
        <TriMenu menuData={menuData}/>
      </main>
      <Footer />
    </>
  );
};

export default MealsAndShawarma;

const currPath = "pizza-parlor/shawarma";
const menuData = [
  {
    id: 0,
    name: "Shawarma Cones",
    sub: "",
    path: currPath + "/0",
  },
  {
    id: 1,
    name: "Meals",
    sub: "",
    path: currPath + "/1",
  },
  {
    id: 2,
    name: "Add-ons",
    sub: "",
    path: currPath + "/2",
  },
];
