import React from "react";
import SubTitle from "./../misc/SubTitle";
import "./modal.scss";
const CoveredModal = (props) => {
  const { name, img, ingredients, price, isVege } = props.menuItem;
  return (
    <div className={`modal ${props.visible === true ? "visible" : ""}`}>
      <div className="content-container">
        <button className="modal-close-button" onClick={props.trigger}>
          X
        </button>
        <div
          className="modal-img-container"
          style={{ backgroundImage: `url(${img})` }}
        ></div>
        <div className="modal-details-container">
          <div className="info">
            <SubTitle title={name} />
            <br />
            <p>
              {Object.entries(price).map((item, key) => {
                return (
                  <span key={key} className="price-item">
                    {item[0]}{" "}
                    {item[1] !== null ? (
                      <>
                        : <b>&#8369; {`${item[1]}`}</b>
                      </>
                    ) : null}
                    <br />
                  </span>
                );
              })}
            </p>
            <br />
            {isVege ? (
              <div className="tag-parent">
                <span className="tag-2">Vegetarian's Choice</span>
              </div>
            ) : null}
            <br />
            <p>{ingredients}</p>
          </div>
          <a
            href="https://m.me/pizzaitalianvero"
            target="_blank"
            rel="noreferrer"
          >
            Message us to order
          </a>
        </div>
      </div>
    </div>
  );
};

export default CoveredModal;
