import React from "react";
import MenuGrid from "../components/MenuGrid/MenuGrid";
import "./home.scss";
import FeaturedImage from "../assets/pizza-parlor/pizza/favorite/daet-favorite.png";
import MinimalFooter from "../components/footer/MinimalFooter";
const Home = () => {
  return (
    <div className="base">
      <img
        src={FeaturedImage}
        alt="Daet's Favourite"
        className="featured-image"
      />
      <div className="featured">
        <div className="history-btn-container"></div>
        <MinimalFooter />
      </div>
      <MenuGrid
        menuData={menuData}
        clickable={true}
        to="/about-us"
        centerText="ABOUT US"
      />
    </div>
  );
};

export default Home;

const menuData = [
  {
    id: 1,
    name: "PIZZA PARLOR MENU",
    sub: "Explore our menu",
    path: "pizza-parlor",
  },
  {
    id: 4,
    name: "FROZEN PIZZA",
    sub: "If you like cheaper & taste our pizza in non-working time",
    path: "frozen-pizza",
  },
  //If you like cheaper or in non-working time
  {
    id: 2,
    name: "DELY SHOP",
    sub: "Brankolei's super food collection",
    path: "dely-shop",
  },
  {
    id: 3,
    name: "BUSINESS TOOL",
    sub: "If you like to make business with us",
    path: "business-tool",
  },
];
