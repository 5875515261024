import React, { useState } from "react";
import NavBar from "../components/navigation/NavBar";
import { useParams } from "react-router-dom";
import Title from "../components/misc/Title";
import SubTitle from "../components/misc/SubTitle";
import frozen from "../data/frozen";
import Footer from "../components/footer/Footer";
import "./prep.scss";
import FrozenCard from "../components/card/FrozenCard";
import Fade from "react-reveal/Fade";
import FrozenCard2 from "../components/card/FrozenCard2";
import ImageOnlyModal from "../components/modal/ImageOnlyModal";

let isPizzaBase = false;
const FrozenPizzaItem = () => {
  const [visible, setvisible] = useState(false);
  const { categoryId } = useParams();
  isPizzaBase = categoryId == 3;
  const menuData = frozen[categoryId];
  const trigger = () => {
    if (visible) {
      setvisible(false);
    } else {
      setvisible(true);
    }
  };
  return (
    <>
      <NavBar />
      <main>
      <div id="swipe-up-indicator">
          Swipe Up
        </div>
        <Title withButton={true} title={menuData.type} />
        <hr />
        <br />
        {!isPizzaBase ? (
          <>
            <FrozenCard pizza={menuData.pizza} />
            <MessageUsButton
              price={menuData.price}
              description={menuData.description}
            />
          </>
        ) : (
          <>
            <FrozenCard2 pizza={menuData.pizza} />
            <MessageUsButton
              price={menuData.price}
              description={menuData.description}
            />
          </>
        )}
        <Preparation img={menuData.img} onClick={() => trigger()} />
        <Packaging img={menuData.img} />
      </main>
      <Footer />
      <ImageOnlyModal
        img={menuData.img[0]}
        visible={visible}
        trigger={() => setvisible(false)}
      />
    </>
  );
};

const Preparation = (props) => {
  return (
    <div className="prep-section">
      <SubTitle title="PREPARATION" />
      <Fade bottom cascade>
        <img
          className="prep"
          src={props.img[0]}
          alt=""
          onClick={props.onClick}
        />
      </Fade>
    </div>
  );
};

const Packaging = (props) => {
  return (
    <div className="pack-section">
      <SubTitle title="PACKAGING" />
      <div className="package">
        <Fade bottom cascade>
          <img className="prep" src={props.img[1]} alt="" />
          <img className="prep" src={props.img[2]} alt="" />
        </Fade>
      </div>
    </div>
  );
};

const MessageUsButton = (props) => {
  return (
    <section className="frozen-price">
      {props.price != null ? <h3>For only &#8369; {props.price}</h3> : null}
      {props.price != null ? <p class="special-tag">Save <b> &#8369;{props.description}</b> from the regular price</p> : <p>{props.description}</p>}
      
      <br />
      <a href="https://m.me/pizzaitalianvero" target="_blank" rel="noreferrer">
        Message us to order
      </a>
    </section>
  );
};

export default FrozenPizzaItem;
