import React from "react";
import NavBar from "../components/navigation/NavBar";
import { Link, useParams } from "react-router-dom";
import Title from "../components/misc/Title";
import Pizza from "../data/pizza";
import PizzaCard from "../components/card/PizzaCard";
import Footer from "../components/footer/Footer";
const PizzaItem = () => {
  let { categoryId } = useParams();
  let menuData = Pizza[categoryId];
  return (
    <>
      <NavBar />
      <main>
        <div id="swipe-up-indicator">Swipe Up</div>
        <Title withButton={true} title={menuData.type} />
        <hr />
        <PizzaCard menu={menuData.menu} clickableTag={true} />
        <div className="vege-btn-c">
          <Link to="/vegetarian">View All Vegetarian Food</Link>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PizzaItem;
