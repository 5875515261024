import React from "react";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";

function MapDisplay(props) {
  const position = [14.11766, 122.9541];
  return (
    <MapContainer center={position} zoom={20} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Tooltip direction="right" permanent>
          {props.name}
        </Tooltip>
      </Marker>
    </MapContainer>
  );
}

export default MapDisplay;
