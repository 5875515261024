import ThreeCheese from "../assets/pizza-parlor/pizza/budget/3-cheese.png";
import HamAndCheese from "../assets/pizza-parlor/pizza/budget/ham-and-cheese.png";
import Hawaiian from "../assets/pizza-parlor/pizza/budget/hawaiian.png";

import FourCheese from "../assets/pizza-parlor/pizza/classic/4-cheese.png";
import FourSeason from "../assets/pizza-parlor/pizza/classic/4-season.png";
import Capriciossa from "../assets/pizza-parlor/pizza/classic/capriciossa.png";
import Pepperoni from "../assets/pizza-parlor/pizza/classic/pepperoni.png";
import Serbiana from "../assets/pizza-parlor/pizza/classic/serbiana.png";
import TunaNapoly from "../assets/pizza-parlor/pizza/classic/tuna-napoly.png";

import DaetFavorite from "../assets/pizza-parlor/pizza/favorite/daet-favorite.png";
import DaetOnTheGo from "../assets/pizza-parlor/pizza/favorite/daet-on-the-go.png";
import PepperoniHotPeppers from "../assets/pizza-parlor/pizza/favorite/pepperoni-hot-peppers.png";
import ShrimpAndGarlic from "../assets/pizza-parlor/pizza/favorite/shrimp-and-garlic.png";
import Vegetarian from "../assets/pizza-parlor/pizza/favorite/vegetarian.png";

import SixCheese from "../assets/pizza-parlor/pizza/overload/6-cheese.png";
import AngusBeef from "../assets/pizza-parlor/pizza/overload/angus-beef.png";
import CyprusOverload from "../assets/pizza-parlor/pizza/overload/cyprus-overload.png";
import HawaiianOverload from "../assets/pizza-parlor/pizza/overload/hawaiian-overload.png";
import ItalianOverload from "../assets/pizza-parlor/pizza/overload/italian-overload.png";
import MeatLoversChoice from "../assets/pizza-parlor/pizza/overload/meat-lovers-choice.png";

const pizza = [
  //////////////////////////////////////Budget Pizza////////////////////////
  {
    type: "Budget",
    menu: [
      {
        name: "3 Cheese",
        img: ThreeCheese,
        isVege: true,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, parmesan cheese, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Ham & Cheese",
        img: HamAndCheese,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, ham, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Hawaiian",
        img: Hawaiian,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, ham, pineapple, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
    ],
  },
  //////////////////////////////////////Classic Pizza////////////////////////
  {
    type: "Classic",
    menu: [
      {
        name: "4 Cheese",
        isVege: true,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, parmesan cheese, (blue or cheddar cheese optional), spices",
        img: FourCheese,
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Pepperoni",
        img: Pepperoni,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, pepperoni sausage, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Capriciossa",
        img: Capriciossa,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, mushroom, ham, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Srbiana",
        img: Serbiana,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, bacon, egg, onion, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Tuna Napoly",
        img: TunaNapoly,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, tuna, black olives, onion, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "4 Season",
        img: FourSeason,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, 1/4 bacon, onion, 1/4 ham, 1/4 pepperoni sausage, 1/4 pineapple, ham, spices",
        price: {
          regular: 198,
          family: 348,
          party: null,
        },
      },
    ],
  },

  //////////////////////////////////////Favorite Pizza////////////////////////
  {
    type: "Favorite",
    menu: [
      {
        name: "Daet Favorite",
        img: DaetFavorite,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, ground beef, corn, onion, mushrooms, ham, fresh peppers, black olives, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Vegetarian",
        img: Vegetarian,
        isVege: true,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, fresh peppers, mushrooms, onion, basil tomato, black olives, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Shrimp & Garlic",
        img: ShrimpAndGarlic,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, shrimp, garlic, onion, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Pepperoni Hot Peppers",
        img: PepperoniHotPeppers,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, pepperoni sausage, fresh peppers, Chili flakes",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Daet On The Go",
        img: DaetOnTheGo,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, bacon, pineapple, pili nuts, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
    ],
  },
  //////////////////////////////////////Overload Pizza////////////////////////
  {
    type: "Overload",
    menu: [
      {
        name: "6 Cheese",
        img: SixCheese,
        isVege: true,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, Mixture of different mozzarella cheeses, parmesan, cream cheese, cheddar cheese and one more, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Hawaiian Overload",
        img: HawaiianOverload,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, 2X more mozzarella cheeses, double ham, fresh peppers, double pineapple, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Cyprus Overload",
        img: CyprusOverload,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, bacon, pepperoni, onion, capers, black olives, green olives, basil, tomato, mushrooms, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Italian Overload",
        img: ItalianOverload,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, ham, bacon, pepperoni, onion, capers, black olives, bell peppers, basil, tomato, mushrooms, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Angus Beef Overload",
        img: AngusBeef,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, angus beef, mushrooms, onion, Worcestershire sauce, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
      {
        name: "Meat Lover's Choice",
        img: MeatLoversChoice,
        isVege: false,
        ingredients:
          "fresh dough- Italian way-Hand tossed, our originally tomato sauce for pizza, mozzarella cheeses, ham, bacon, pepperoni, ground beef, sausage, onion, black olives, bell peppers, spices",
        price: {
          regular: null,
          family: null,
          party: null,
        },
      },
    ],
  },
];

export default pizza;
