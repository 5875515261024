import React from "react";
import "./modal.scss";

const ImageOnlyModal = (props) => {
  return (
    <div className={`modal ${props.visible === true ? "visible" : ""}` }>
      <div className="static-container">
        <button className="modal-close-button" onClick={props.trigger}>
          CLOSE
        </button>
        <img src={props.img} alt="Ice Cream VS Gelatto" />
      </div>
    </div>
  );
};

export default ImageOnlyModal;
