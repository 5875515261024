import React from "react";
import Footer from "../components/footer/Footer";
import Title from "../components/misc/Title";
import NavBar from "../components/navigation/NavBar";
import TriMenu from "../components/MenuGrid/TriMenu"

const Vegetarian = () => {
    return (
        <>
        <NavBar />
        <main>
        <div id="swipe-up-indicator">
            Swipe Up
          </div>
          <Title withButton={true} title="Vegetarian's Choice" />
          <hr />
          <TriMenu menuData={menuData}/>
        </main>
        <Footer />
      </>
    )
}

export default Vegetarian

const currPath = "vegetarian";
const menuData = [
  {
    id: 0,
    name: "Pizza",
    sub: "",
    path: currPath + "/0",
  },
  {
    id: 1,
    name: "Pasta",
    sub: "",
    path: currPath + "/1",
  },
  {
    id: 2,
    name: "Meals & Shawarma",
    sub: "",
    path: currPath + "/2",
  },
];
