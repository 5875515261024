import React, { useState } from "react";
import {
  faFacebookMessenger,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./minimal_footer.scss";
const MinimalFooter = () => {
  const [date, setDate] = useState(new Date().getFullYear());
  return (
    <footer className="transparent">
      <div>
        <section>
          <h6>SOCIAL LINKS</h6>
          <a
            href="https://facebook.com/pizzaitalianvero"
            target="_blank"
            rel="noreferrer"
            className="light"
          >
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a
            href="https://m.me/pizzaitalianvero"
            target="_blank"
            rel="noreferrer"
            className="light"
          >
            <FontAwesomeIcon icon={faFacebookMessenger} />
          </a>
        </section>
        <section>
          <h6>CONTACT US</h6>
          <p className="light">
            Smart : (+63) 0929 284 8041 <br />
            Globe : (+63) 0956 190 8117
          </p>
        </section>
      </div>
      <hr />
      <section>
        <p className="light">
          &copy; {date}. Brankolei Pizza Italian Vero.
          <br /> All Rights Reserved
        </p>
      </section>
    </footer>
  );
};

export default MinimalFooter;
