import Balsamic from "../assets/dely/balsamic.jpg";
import Olives from "../assets/dely/black-olives.jpg";
import GreekSaladDressing from "../assets/dely/greek_salad_dressing.jpeg";
import Molinera from "../assets/dely/molinera.jpg";
import ParmesanCheese from "../assets/dely/parmesan_cheese.jpeg";
import MolineraExtra from "../assets/dely/molinera-extra.png";
import ShreddedCheese from "../assets/dely/shredded-mozzarella-cheese.jpg";

const dely = [
  {
    id: 2,
    name: "Black Olives",
    img: Olives,
    ingredients:
      "150 ml ( 75grams drain weight) and \n1000 ml ( 500grams Drain weight )",
    price: ["150ml (sliced)", "500grams"],
  },

  {
    id: 1,
    name: "Balsamic Vinegar",
    img: Balsamic,
    ingredients: "Do not need to pay for expensive bottle.",
    price: ["200ml", "500ml", "1L"],
  },
  {
    id: 0,
    name: "Parmesan Cheese",
    img: ParmesanCheese,
    ingredients: "Grated Parmesan Cheese",
    price: ["100g"],
  },

  // {
  //   id: 6,
  //   name: "Arla Shredded Mozzarella Cheese",
  //   img: ShreddedCheese,
  //   ingredients:
  //     "Their amazing stretchability characteristics is not known for nothing. Their ability to change color beautifully when heated is also the reason chefs around the world like to incorporate shredded mozzarella in their dishes.",
  //   price: [],
  // },
  // {
  //   id: 3,
  //   name: "Greek Salad Dressing",
  //   img: GreekSaladDressing,
  //   ingredients: "Feta cheese, olive oil, olives & spcies. (4-6 servings)",
  //   price: [],
  // },
  // {
  //   id: 4,
  //   name: "Molinera Mediterranean Olive Oil Blend",
  //   img: Molinera,
  //   ingredients: "Molinera Mediterranean Olive Oi",
  //   price: [],
  // },
  // {
  //   id: 5,
  //   name: "Molinera Extra Virgin Olive Oil",
  //   img: MolineraExtra,
  //   ingredients: "Molinera Extra Virgin Olive Oil",
  //   price: [],
  // },
];

export default dely;
